.wtree h1 {
  padding: 0 0 10px 0;
}

 ol, ul {
  padding-left: 0rem !important;
}


.wtree ol {
  margin-left: 30px;
  counter-reset: item;
}

.wtree li {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
}
.wtree li:before {
  content: "";
  counter-increment: item;
  position: absolute;
  top: -10px;
  left: -30px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 30px;
  height: 15px;
}
.wtree li:after {
  position: absolute;
  content: "";
  top: 5px;
  left: -30px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 30px;
  height: 100%;
}
.wtree li:last-child:after {
  display: none;
}
.wtree li span {
    font-size: 16px;
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
  /* color: #666666; */
  color: #000;
  text-decoration: none;
}
.wtree li span:before {
  /* content: counters(item, ".") " ";  */
}

.wtree li span:hover, .wtree li span:focus {
  font-weight: bold;
  /* border: 1px solid #474747; */
}
.wtree li span:hover + ol li span, .wtree li span:focus + ol li span {
    font-weight: bold;
  /* border: 1px solid #474747; */
}
.wtree li span:hover + ol li:after, .wtree li span:hover + ol li:before, .wtree li span:focus + ol li:after, .wtree li span:focus + ol li:before {
  /* border-color: #474747; */
}

.wtree li span {
  background-color: #DDF3FE;
}

.wtree li li span {
  background-color: #DDEBC8;
}

.wtree li li li span {
  background-color: #FEFCD5;
}

.wtree .display {
  margin-right: 12px;
  font-weight: bold;
}

input, label {
  margin: 12px 0px 20px 0px;
}

.wtree label {
  padding-left: 6px;
  padding-right: 12px;
}

#radio-1:checked + label ~ ol li ol li {
  display: none;
}

#radio-2:checked + label ~ ol li ol li ol li {
  display: none;
}

#radio-3:checked + label ~ li ol li ol li {
  display: none;
}
.wtree span p{
    float: right;
}